import Vue from 'vue'
import VueRouter from 'vue-router'

// 通过import XXX form XXX 加载组件的方式，无论组件有没有被用到，都会被加载进来
// import Recommend from '../views/Recommend'
// import Singer from '../views/Singer'
// import Rank from '../views/Rank'
// import Search from '../views/Search'

// 实现组件按需加载
const Recommend = (resolve) => {
  import('../views/Recommend').then((module) => {
    resolve(module)
  })
}
const Detail = (resolve) => {
  import('../views/Detail').then((module) => {
    resolve(module)
  })
}
const Singer = (resolve) => {
  import('../views/Singer').then((module) => {
    resolve(module)
  })
}
const Rank = (resolve) => {
  import('../views/Rank').then((module) => {
    resolve(module)
  })
}
const Search = (resolve) => {
  import('../views/Search').then((module) => {
    resolve(module)
  })
}
const Account = (resolve) => {
  import('../views/Account').then((module) => {
    resolve(module)
  })
}

Vue.use(VueRouter)

const routes = [
  {
    // 重定向路由，默认页面
    path: '/',
    redirect: '/Recommend'
  },
  {
    path: '/Recommend',
    component: Recommend,
    children: [
      {
        path: 'Detail/:id/:type',
        component: Detail
      }
    ]
  },
  {
    path: '/Singer',
    component: Singer,
    children: [
      {
        path: 'Detail/:id/:type',
        component: Detail
      }
    ]
  },
  {
    path: '/Rank',
    component: Rank,
    children: [
      {
        path: 'Detail/:id/:type',
        component: Detail
      }
    ]
  },
  {
    path: '/Search',
    component: Search
  },
  {
    path: '/Account',
    component: Account
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
