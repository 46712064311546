<template>
  <transition
  :css="false"
  @enter="enter"
  @leave="leave"
  >
<div class="normal-player" v-show="this.isFullScreen">
  <div class="player-wrapper">
    <PlayerHeader></PlayerHeader>
    <PlayerMiddle :currentTime = currentTime></PlayerMiddle>
    <PlayerBottom :totalTime = totalTime :currentTime = currentTime></PlayerBottom>
  </div>
  <div class="player-bg">
    <img :src="currentSong.picUrl + '?param200y200'" alt="">
  </div>
</div>
  </transition>
</template>

<script>
import PlayerHeader from './PlayerHeader'
import PlayerMiddle from './PlayerMiddle'
import PlayerBottom from './PlayerBottom'
import { mapGetters, mapActions } from 'vuex'
import Velocity from 'velocity-animate'
import 'velocity-animate/velocity.ui'
export default {
  name: 'NormalPlayer-one',
  components: {
    PlayerHeader,
    PlayerMiddle,
    PlayerBottom
  },
  computed: {
    //  通过辅助函数把getter映射成局部计算属性
    ...mapGetters([
      'isFullScreen',
      'currentSong'
    ])
  },
  methods: {
    ...mapActions([
      'getSongLyric'
    ]),
    enter (el, done) {
      Velocity(el, 'transition.shrinkIn', { duration: 500 }, function () {
        done()
      })
    },
    leave (el, done) {
      Velocity(el, 'transition.shrinkOut', { duration: 500 }, function () {
        done()
      })
    }
  },
  watch: {
    currentSong (newValue, oldValue) {
      if (newValue.id === undefined) {
        return
      }
      this.getSongLyric(newValue.id)
    }
  },
  props: {
    totalTime: {
      type: Number,
      default: 0,
      required: true
    },
    currentTime: {
      type: Number,
      default: 0,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/variable";
@import "../../assets/css/mixin";
.normal-player{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: pink;
  @include bg_sub_color();
  .player-wrapper{
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 999;
  }
  .player-bg{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
      height: 100%;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%,-50%);
      //filter 属性定义元素（通常是 <img>）的视觉效果（如模糊和饱和度)
      filter: blur(10px);
    }
  }
}
</style>
