<template>
<div class="header">
  <div class="header-left" @click="hiddenNormalPlayer"></div>
  <div class="header-title">
    <h3>{{currentSong.name}}</h3>
    <p>{{currentSong.singer}}</p>
  </div>
  <div class="header-right"></div>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PlayerHeader-one',
  data: function () {
    return {
      themes: ['theme', 'theme1', 'theme2'],
      index: 0
    }
  },
  methods: {
    changeTheme () {
      this.index++
      if (this.index >= this.themes.length) {
        this.index = 0
      }
      document.documentElement.setAttribute('data-theme', this.themes[this.index])
    },
    back () {
      window.history.back() // 回到上一页
    },
    ...mapActions([
      'setFullScreen',
      'setMiniPlayer'
    ]),
    hiddenNormalPlayer () {
      this.setFullScreen(false)
      this.setMiniPlayer(true)
    }
  },
  props: {
    // title: {
    //   type: String,
    //   default: ' ',
    //   required: true
    // }
  },
  computed: {
    ...mapGetters([
      'currentSong'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/variable";
@import "../../assets/css/mixin";
.header{
  position: relative;
  z-index: 999;
  width: 100%;
  height: 100px;
  //background:#f00;
  //弹性盒子
  display: flex;
  justify-content: space-between;
  //选择背景颜色
  //@include bg_color();

}
.header-title{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  //文字垂直居中
  //line-height: 100px;
  color:#fff;
  //font-weight: bold;
  @include font_size($font-medium);
  @include no-wrap();
}
.header-left,.header-right{
  width:84px;
  height: 84px;
  //background: #000;
  margin-top: 8px;
}
.header-left{
  @include bg_img('../../assets/images/down');
}
.header-right{
  //@include bg_img('../../assets/images/more');
}
</style>
