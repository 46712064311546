import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import './assets/css/base.scss'
import VueLazyload from 'vue-lazyload'
import Loading from './plugin/loading/index'
// import VConsole from 'vconsole'

Vue.use(VueLazyload)

// 借用‘fastclick’解决移动端100-300ms的延迟问题·
fastclick.attach(document.body)

Vue.config.productionTip = false
Vue.use(Loading, {
  title: '正在加载...'
})
Vue.use(VueLazyload, {
  // 可以通过配置loading来设置图片还未记载好之前的占位图片
  loading: require('./assets/images/loading.png')
})
// const vconsole = new VConsole()
// Vue.use(vconsole)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
