<template>
<div class="tabbar">
  <router-link tag="div" class="item" to="/Recommend">
    <span>推荐</span>
  </router-link>
  <router-link tag="div" class="item" to="/Singer">
    <span>歌手</span>
  </router-link>
  <router-link tag="div" class="item" to="/Rank">
    <span>排行</span>
  </router-link>
  <router-link tag="div" class="item" to="/Search">
    <span>搜索</span>
  </router-link>
</div>
</template>

<script>
export default {
  name: 'Tabbar-two'
}
</script>

<style scoped lang="scss">
@import "../assets/css/variable";
@import "../assets/css/mixin";
.tabbar{
  position: relative;
  //z-index: 999;
  width: 100%;
  height: 84px;
@include bg_sub_color();
  display: flex;
  justify-content: space-around;
}
.item{
  span{
    line-height: 84px;
    @include font_size($font-medium);
    @include font_color();
  }
  &.router-link-active{
    span{
    @include font_active_color();
    }
    border-bottom: 5px solid #000;
    @include border_color();
  }
}
</style>
