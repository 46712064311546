<template>
  <div id="wrapper" ref="wrapper">
    <!--  插槽 占位符-->
    <slot></slot>
  </div>
</template>

<script>
import IScroll from 'iscroll/build/iscroll-probe' // 专业版本 监听滚动细节 扩展性更好
export default {
  name: 'ScrollView',
  // 元素渲染完毕之后
  mounted () {
    this.initScroll()
  },
  methods: {
    initScroll () {
      // 定义一个滚动插件，并绑定给Vue
      this.iscroll = new IScroll(this.$refs.wrapper, {
        mouseWheel: true,
        scrollbars: false,
        probeType: 3, // 像素级的触发scroll事件
        //  解决拖拽卡顿问题
        scrollX: false,
        scrollY: true,
        disablePointer: true,
        disableTouch: false, // 不会监听 touch 相关事件
        disableMouse: true, // 不会监听鼠标相关的事件
        // IScroll.js移动端点击事件被阻止的解决方案
        preventDefault: false,
        onClick: true

      })
      // setTimeout(() => {
      //   console.log(this.iscroll.maxScrollY)
      //   this.iscroll.refresh()
      //   console.log(this.iscroll.maxScrollY)
      // }, 5000)
      //  1.创建一个观察者对象
      /*
      MutationObserver构造函数只要监听到了指定内容的变化，就会执行传入的回调函数
      mutationList:发生变化的数组
      observer:观察者对象
      **/
      const observer = new MutationObserver((mutationList, observer) => {
        // console.log(mutationList)
        console.log(this.iscroll.maxScrollY)
        this.iscroll.refresh()
        console.log(this.iscroll.maxScrollY)
      })
      //  2.告诉观察者对象我们需要观察什么内容
      const config = {
        childList: true, // 观察目标子节点的变化，添加或删除
        subtree: true, // 默认为false,设置为 true 可观察后代节点
        attributeFilter: ['height', 'offsetHeight']// 观察特定属性
      }
      //  3.告诉观察者对象，我们需要观察谁，需要观察什么内容
      /*
       第一个参数：告诉观察者对象我们需要观察谁
       第二个参数：告诉观察者对象我们需要观察什么内容
      * */
      observer.observe(this.$refs.wrapper, config)
    },
    //  提供一个监听滚动距离的方法给外界使用
    scrolling (fn) {
      this.iscroll.on('scroll', function () {
        fn(this.y)
      })
    },
    refresh () {
      setTimeout(() => {
        this.iscroll.refresh()
      }, 100)
    },
    scrollTo (x, y, time) {
      this.iscroll.scrollTo(x, y, time)
    }
  }
}

</script>

<style scoped lang="scss">
#wrapper{
  width: 100%;
  height: 100%;
  //overflow: hidden;
  //position: relative;
}
</style>
