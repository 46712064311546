<template>
<!--<div class="header" @click="changeTheme">-->
<!--  <div class="header-left"></div>-->
<!--  <p class="header-title">点点琳音乐</p>-->
<!--  <div class="header-right" @click.stop="accountClick"></div>-->
<!--</div>-->
  <div class="header" @click="changeTheme">
<!--    插槽不能直接设置样式-->
    <div class="left">
      <slot name="left"> 左边</slot>
    </div>
    <div class="center">
      <slot name="center">中间</slot>
    </div>
    <div class="right">
      <slot name="right">右边</slot>
    </div>
<!--    <div class="header-left"></div>-->
<!--    <p class="header-title">点点琳音乐</p>-->
<!--    <div class="header-right" @click.stop="accountClick"></div>-->
  </div>
</template>

<script>
export default {
  name: 'Header-one',
  data: function () {
    return {
      themes: ['theme', 'theme1', 'theme2'],
      index: 0
    }
  },
  methods: {
    changeTheme () {
      this.index++
      if (this.index >= this.themes.length) {
        this.index = 0
      }
      document.documentElement.setAttribute('data-theme', this.themes[this.index])
    }
    // accountClick () {
    //   this.$router.push('/account')
    // }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/variable";
@import "../assets/css/mixin";
.header{
  //position: fixed;
  //z-index: 999;
  width: 100%;
  height: 100px;
  background:#f00;
  //弹性盒子
  display: flex;
  justify-content: space-between;
  //选择背景颜色
  @include bg_color();
}
//.header-title{
//  text-align: center;
//  //文字垂直居中
//  line-height: 100px;
//  color:#fff;
//  font-weight: bold;
//  @include font_size($font-medium);
//}
.left,.right{
  width:84px;
  height: 84px;
  //background: #000;
  margin-top: 8px;
  *{
    width: 100%;
    height: 100%;
  }
}
//.header-left{
//  @include bg_img('../assets/images/logo');
//}
//.header-right{
//  @include bg_img('../assets/images/account');
//}
</style>
